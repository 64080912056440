<template>
    <form-view 
        v-slot="scope"
        title="Редактирование ключа"
        update
        url-update="/code/update/:id"
        action-one="code/one"
    >
        <code-form :model="scope.model" @success="scope.onSuccess" />
    </form-view>
</template>
<script>
import FormView from '@/components/crud/FormView.vue'
import CodeForm from '@/components/forms/CodeForm.vue'
export default {
    name: 'CodeUpdate',
    components: { FormView, CodeForm }
}
</script>