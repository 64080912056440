<template>
    <v-form v-if="!_.isNil(model)">
        <v-alert
          v-if="!_.isNil(summaryError)"
          dense
          type="error"
        >
            {{ summaryError }}
        </v-alert>

        <v-row justify="start" align="center">
            <v-col class="col-auto">
                <span class="text-body-1">{{ model.value }}</span>
            </v-col >
        </v-row>

        <v-switch
          v-model="form.active"
          label="Активность"
        ></v-switch>

        <v-select
          v-model="$v.form.license_type_id.$model"
          readonly
          :error-messages="getErrors(`form.license_type_id`)"
          required
          :items="license_types.items"
          :loading="license_types.loading"
          label="Тип лицензии"
        ></v-select>

        <v-divider class="my-4" />

        <action-buttons 
            :waiting-save-and-back="waiting.save.back"
            :waiting-save-and-update="waiting.save.update"
            @back="back"
            @save="save"
        />
    </v-form>
</template>
<script>
import DataListMixin from '@/mixins/DataListMixin.vue'
import { errorMixin, saveMixin } from '@/mixins/formMixin'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import ActionButtons from '@/components/crud/ActionButtons.vue'

export default {
    name: 'CodeForm',
    components: { ActionButtons },
    props: {
        model: {
            type: Object
        }
    },
    mixins: [errorMixin, saveMixin, DataListMixin, validationMixin],
    data() {
        return {
            storeModule: 'code',
            license_types: {
                loading: true,
                items: []  
            },
            form: {
                active: true,
                license_type_id: null
            }
        };
    },
    validations() {
        return {
            form: {
                license_type_id: {
                    required
                }
            }
        }
    },
    async created() {
        // Fetch license types
        this.license_types.loading = true;
        this.license_types.items = await this.getDataList('license_type', {
            label: 'name',
            value: 'id'
        });
        this.license_types.loading = false;
    }
}
</script>
<style scoped lang="scss">

</style>